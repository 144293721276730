import React from 'react';
import {useDispatch} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import {updateExercise} from "../../reducers/exercisesSlice";
import {useForm} from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from '@material-ui/icons/Check';

export default function Exercise(props) {

    const positionValues = ['Incline Bench', 'Flat Bench/Box', 'Stability Ball', 'Floor', 'Standing'];
    const progressionHipBridgeValues = ['Level 1 (Floor Bridge)', 'Level 2 (Bench Hip Bridge)', 'Level 3 (Ball Hip Bridge)'];
    const progressionPressValues = ['Level 1 (In and Outs, SB Chest Press)', 'Level 2 (DB Chest Press, Incline DB Bench)', 'Level 2 (Bench Press)', 'Level 3 (DB Chest Fly, Ball MB Chest Press)', 'Level 3 (Ball Chest Fly, TRX Press)', 'Level 3 (Ball Chest Press)'];
    const progressionPushUpValues = ['Level 1 (Modified Pushup, Wall Pushup, Flat Tire)', 'Level 2 (Bench Pushup, Ball Pushup)', 'Level 3 (Pushup)'];
    const progressionSquatValues = ['Level 1 (Assisted Sit to Stand, Hand Held)', 'Level 2 (Bench Squat, Box Squat, Squat with Ball)', 'Level 3 (Banded Squat, Squat with Weight)', 'Level 3 (Assisted Pistol Squat, Goblet Squat)', 'Level 3 (Squat Kick)'];
    const progressionStepUpsValues = ['Level 1 (6" Step Up)', 'Level 2 (12" Step Up)', 'Level 3 (Bench Step Up)'];
    const progressionOverheadPressValues = ['Level 1 (MB Overhead Press, SB Overhead Press)', 'Level 2 (Dumbbell Overhead Press)', 'Level 3 (Resistance Band Overhead Press)'];
    const progressionSingleArmTricepValues = ['Level 1 (Resistance Band, Arm Weight)', 'Level 2 (Extension)', 'Level 3 (Kickback)'];
    const progressionDoubleArmTricepValues = ['Level 1 (SB, MB)', 'Level 2 (RB Triceps Push Down)', 'Level 3 (Machine)'];
    const progressionDoubleArmBicepValues = ['Level 1 (SB, MB)', 'Level 2 (Weighted Bar, Resistance Band)', 'Level 3 (Machine)'];
    const progressionSingleArmBicepValues = ['Level 1 (Sandbell, Resistance Band)', 'Level 2 (DB)', 'Level 3 (Machine)'];
    const progressionLegCurlValues = ['Level 1 (SB Curl)', 'Level 2 (Resistance Band Hamstring Curl)', 'Level 3 (Machine)'];
    const progressionLungeValues = ['Level 1 (SB Lunge)', 'Level 2 (Reverse Lunge)', 'Level 3 (Lunge)'];
    const heightValues = ['6"', '12"', '18"', '24"'];
    const heightStepUpToOHPressValues = ['6"', '12"', 'Bench'];
    const timeValues = ['10 sec', '20 sec', '30 sec', '1 min', '5 min', '10 min', '15 min', '20 min', '25 min', '30 min', '35 min', '40 min', '45 min', '50 min', '55 min', '60 min'];
    const timeunitsValues = ['Seconds', 'Minutes'];
    const granularTimeValues = ['15 sec', '30 sec', '45 sec', '1 min', '1 min, 15 sec', '1 min, 30 sec', '1 min, 45 sec', '2 min', '2 min, 15 sec', '2 min, 30 sec', '2 min, 45 sec', '3 min', '3 min, 15 sec', '3 min, 30 sec', '3 min, 45 sec', '4 min', '4 min, 15 sec', '4 min, 30 sec', '4 min, 45 sec', '5 min'];
    const minuteTimeValues = ['1-5 sec', '6-10 sec', '11-15 sec', '16-20 sec', '21-25 sec', '26-30 sec', '31-35 sec', '36-40 sec', '41-50 sec', '51-60 sec'];
    const weightValues = ['None', 'Body Weight', '4 lbs', '5 lbs', '6 lbs', '8 lbs', '10 lbs', '12 lbs', '15 lbs', '18 lbs', '20 lbs', '25 lbs', '30 lbs', '35 lbs', '40-50 lbs', '55-70 lbs', 'Resistance Band - Light', 'Resistance Band - Medium', 'Resistance Band - Heavy'];
    const loadValues = ['1-5', '6-10'];
    const equipmentValues = ['6"', '12"', 'Bar', 'Barbell', 'Bench', 'Bosu', 'Bowl Pins', 'Box', 'Box - 6"', 'Box - 12"', 'Box - 18"', 'Cable', 'Captains Chair', 'Cones', 'Dumbbells', 'Floor', 'Gliders', 'Horizontal Bar', 'Hurdles - Low', 'Hurdles - Tall', 'Kettlebell', 'Ladder - Floor', 'Loop Band', 'Machine', 'Mat', 'Medicine Ball', 'Plastic Golf Balls', 'Playground Ball', 'Pullup Bar', 'Resistance Band - Light', 'Resistance Band - Medium', 'Resistance Band - Heavy', 'Rip Cord', 'Rope', 'Sandbag', 'Sandbell', 'Slam Ball', 'Sled ','Stabillity Ball', 'Step', 'TRX', 'Wall'];
    const pushupMovementValues = ['Pushup', 'Modified Pushup', 'Flat Tire Pushup'];
    const distanceValues = ['20 feet', '30 feet', '40 feet', '50 feet', '100ft/30 m', '.25mi/402m', '.5 mi/800m', '.75mi/1207m', '1 mi/1600m', '2 mi/3200m', '3 mi/4800m', '4 mi/6400m', '5 mi/8046m', '6 mi/9656m', '7 mi/11265m', '8 mi/12874m', '9 mi/14484m', '10 mi/16093m'];
    const speedValues = ['.5 mph', '.7 mph', '1 mph', '1.5 mph', '2 mph', '2.5 mph', '3 mph', '3.5 mph', '4 mph', '5 mph', '6 mph', '7 mph', '8 mph', '10 mph', '12 mph', '15 mph'];
    const repsValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11-15', '16-20', '21-25', '26-30'];
    const chestPressRepValues = ['1RM', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11-15', '16-20', '21-25', '26-30'];
    const sitToStandRepValues = ['1-2', '3-4', '5-6', '7-8', '9-10', '11-12', '13-14', '15-16', '17-18', '19-20', '21-22', '23-24', '25-30', '31-35', '36-40', '41-45', '46-50'];
    const setsValues = ['1', '2', '3', '4', '5'];
    const promptsValues = ['Independent', 'PL 1 - Visual / Gestural', 'PL 2 - Verbal / Modeling', 'PL 3 - Tactile / Physical'];
    const promptIndependentValues = ['With Prompt', 'Independent'];
    const yesNoValues = ['Yes', 'No'];

    const {register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const [discardExerciseDialogIsOpen, setDiscardExerciseDialogIsOpen] = React.useState(false);

    const onSubmit = data => {
        console.log('submit of exercise called with:', data);
        dispatch(updateExercise(makeObject('finished', true)));
    };

    const togglePanel = () => {
        if (props.exercise.finished) {
            dispatch(updateExercise(makeObject('finished', false)));
        }
    };

    const makeObject = (key, value) => {
        //let copiedObject = Object.assign({}, props.exercise);
        let copiedObject = {id: props.exercise.id};
        copiedObject[key] = value;
        return copiedObject;
    };

    const changeAttribute = (name, event) => {
        dispatch(updateExercise(makeObject(name, event.target.value)));
    };

    const changeCheckedAttribute = (name, event) => {
        dispatch(updateExercise(makeObject(name, event.target.checked)));
    };

    const changeNotes = (event) => {
        dispatch(updateExercise(makeObject('notes', event.target.value)));
    };

    const handleDiscardExerciseDialogOpen = () => {
        setDiscardExerciseDialogIsOpen(true);
    };

    const handleDiscardExerciseDialogClose = () => {
        setDiscardExerciseDialogIsOpen(false);
    };

    const discardExercise = (exercise) => () => {
        setDiscardExerciseDialogIsOpen(false);
        props.discarder(exercise);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={3} maxWidth={800} mx="auto">
                    <ExpansionPanel expanded={!props.exercise.finished} onChange={togglePanel}>
                        <ExpansionPanelSummary
                            aria-controls="exercise-content"
                            id="exercise-header"
                        >
                            <Typography variant="h5" component="span">{props.exercise.name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={3}>

                                {props.exerciseConfig.position ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.position} fullWidth>
                                            <InputLabel htmlFor="position">Position</InputLabel>
                                            <Select
                                                native
                                                label="Position"
                                                inputRef={register}
                                                value={props.exercise.position}
                                                onChange={(e) => changeAttribute('position', e)}
                                                inputProps={{
                                                    name: 'position',
                                                    id: 'position',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {positionValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionHipBridge ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionHipBridge} fullWidth>
                                            <InputLabel htmlFor="progressionHipBridge">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionHipBridge}
                                                onChange={(e) => changeAttribute('progressionHipBridge', e)}
                                                inputProps={{
                                                    name: 'progressionHipBridge',
                                                    id: 'progressionHipBridge',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionHipBridgeValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionPress ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionPress} fullWidth>
                                            <InputLabel htmlFor="progressionPress">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionPress}
                                                onChange={(e) => changeAttribute('progressionPress', e)}
                                                inputProps={{
                                                    name: 'progressionPress',
                                                    id: 'progressionPress',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionPressValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionPushUp ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionPushUp} fullWidth>
                                            <InputLabel htmlFor="progressionPushUp">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionPushUp}
                                                onChange={(e) => changeAttribute('progressionPushUp', e)}
                                                inputProps={{
                                                    name: 'progressionPushUp',
                                                    id: 'progressionPushUp',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionPushUpValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionStepUps ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionStepUps} fullWidth>
                                            <InputLabel htmlFor="progressionStepUps">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionStepUps}
                                                onChange={(e) => changeAttribute('progressionStepUps', e)}
                                                inputProps={{
                                                    name: 'progressionStepUps',
                                                    id: 'progressionStepUps',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionStepUpsValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionOverheadPress ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionOverheadPress} fullWidth>
                                            <InputLabel htmlFor="progressionOverheadPress">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionOverheadPress}
                                                onChange={(e) => changeAttribute('progressionOverheadPress', e)}
                                                inputProps={{
                                                    name: 'progressionOverheadPress',
                                                    id: 'progressionOverheadPress',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionOverheadPressValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionSingleArmTricep ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionSingleArmTricep',
                                                    id: 'progressionSingleArmTricep',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionSingleArmTricepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionDoubleArmTricep ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionDoubleArmTricep',
                                                    id: 'progressionDoubleArmTricep',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionDoubleArmTricepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionDoubleArmBicep ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionDoubleArmBicep',
                                                    id: 'progressionDoubleArmBicep',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionDoubleArmBicepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionSingleArmBicep ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionSingleArmBicep',
                                                    id: 'progressionSingleArmBicep',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionSingleArmBicepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionLegCurl ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionLegCurl',
                                                    id: 'progressionLegCurl',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionLegCurlValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionLunge ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progression} fullWidth>
                                            <InputLabel htmlFor="progression">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progression}
                                                onChange={(e) => changeAttribute('progression', e)}
                                                inputProps={{
                                                    name: 'progressionLunge',
                                                    id: 'progressionLunge',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionLungeValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.progressionSquat ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.progressionSquat} fullWidth>
                                            <InputLabel htmlFor="progressionSquat">Progression</InputLabel>
                                            <Select
                                                native
                                                label="Progression"
                                                inputRef={register}
                                                value={props.exercise.progressionSquat}
                                                onChange={(e) => changeAttribute('progressionSquat', e)}
                                                inputProps={{
                                                    name: 'progressionSquat',
                                                    id: 'progressionSquat',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {progressionSquatValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.height ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.height} fullWidth>
                                            <InputLabel htmlFor="height">Height</InputLabel>
                                            <Select
                                                native
                                                label="Height"
                                                inputRef={register}
                                                value={props.exercise.height}
                                                onChange={(e) => changeAttribute('height', e)}
                                                inputProps={{
                                                    name: 'height',
                                                    id: 'height',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {heightValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.heightStepUpToOHPress ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.heightStepUpToOHPress} fullWidth>
                                            <InputLabel htmlFor="heightStepUpToOHPress">Height</InputLabel>
                                            <Select
                                                native
                                                label="Height"
                                                inputRef={register}
                                                value={props.exercise.heightStepUpToOHPress}
                                                onChange={(e) => changeAttribute('heightStepUpToOHPress', e)}
                                                inputProps={{
                                                    name: 'heightStepUpToOHPress',
                                                    id: 'heightStepUpToOHPress',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {heightStepUpToOHPressValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.time ?
                                    <Grid item xs={12} sm={2}>
                                        <FormControl error={!!errors.time} fullWidth>
                                            <TextField error={!!errors.time}
                                                       name="time"
                                                       type="tel"
                                                       id="time"
                                                       label="Time"
                                                       inputRef={register({pattern: /^[1-9][0-9]{0,1}$/})} // one or two digit number, first number non-zero
                                                       variant="outlined"
                                                       value={props.exercise.time}
                                                       onChange={(e) => changeAttribute('time', e)}
                                                       inputProps={{
                                                           maxLength: 2
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.timeunits ?
                                    <Grid item xs={12} sm={2}>
                                        <FormControl error={!!errors.timeunits} fullWidth>
                                            <InputLabel htmlFor="speed">Time Units</InputLabel>
                                            <Select
                                                native
                                                label="Time Units"
                                                inputRef={register}
                                                value={props.exercise.timeunits}
                                                onChange={(e) => changeAttribute('timeunits', e)}
                                                inputProps={{
                                                    name: 'timeunits',
                                                    id: 'timeunits',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {timeunitsValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.granularTime ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.granularTime} fullWidth>
                                            <InputLabel htmlFor="time">Time</InputLabel>
                                            <Select
                                                native
                                                label="Time"
                                                inputRef={register}
                                                value={props.exercise.time}
                                                onChange={(e) => changeAttribute('time', e)}
                                                inputProps={{
                                                    name: 'granularTime',
                                                    id: 'granularTime',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {granularTimeValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.minuteTime ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.minuteTime} fullWidth>
                                            <InputLabel htmlFor="time">Time</InputLabel>
                                            <Select
                                                native
                                                label="Time"
                                                inputRef={register}
                                                value={props.exercise.time}
                                                onChange={(e) => changeAttribute('time', e)}
                                                inputProps={{
                                                    name: 'minuteTime',
                                                    id: 'minuteTime',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {minuteTimeValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.heartRate ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.heartRateBefore} fullWidth>
                                            <TextField error={!!errors.heartRateBefore}
                                                       name="heartRateBefore"
                                                       type="tel"
                                                       inputRef={register({pattern: /^[1-9][0-9]{1,2}$/})} // two or three digit number, first number non-zero
                                                       id="heartRateBefore"
                                                       label="Heart Rate Before"
                                                       value={props.exercise.heartRateBefore}
                                                       onChange={(e) => changeAttribute('heartRateBefore', e)}
                                                       inputProps={{
                                                           maxLength: 3
                                                       }}/>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.heartRate ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.heartRateAfter} fullWidth>
                                            <TextField error={!!errors.heartRateAfter}
                                                       name="heartRateAfter"
                                                       type="tel"
                                                       inputRef={register({pattern: /^[1-9][0-9]{1,2}$/})} // two or three digit number, first number non-zero
                                                       id="heartRateAfter"
                                                       label="Heart Rate After"
                                                       value={props.exercise.heartRateAfter}
                                                       onChange={(e) => changeAttribute('heartRateAfter', e)}
                                                       inputProps={{
                                                           maxLength: 3
                                                       }}/>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.heartRate ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.heartRateLater} fullWidth>
                                            <TextField error={!!errors.heartRateLater}
                                                       name="heartRateLater"
                                                       type="tel"
                                                       inputRef={register({pattern: /^[1-9][0-9]{1,2}$/})} // two or three digit number, first number non-zero
                                                       id="heartRateLater"
                                                       label="Heart Rate 2 Minutes After"
                                                       value={props.exercise.heartRateLater}
                                                       onChange={(e) => changeAttribute('heartRateLater', e)}
                                                       inputProps={{
                                                           maxLength: 3
                                                       }}/>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.weight ?
                                    <Grid item xs={12} sm={2}>
                                        <FormControl error={!!errors.weight} fullWidth>
                                            <TextField error={!!errors.weight}
                                                       name="weight"
                                                       type="tel"
                                                       inputRef={register}
                                                       id="weight"
                                                       label="Weight (lbs)"
                                                       variant="outlined"
                                                       value={props.exercise.weight}
                                                       onChange={(e) => changeAttribute('weight', e)}
                                                       inputProps={{
                                                           maxLength: 5
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.bodyWeight ?
                                    <Grid item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox name="bodyWeight" />}
                                        label="Body Weight"
                                        inputRef={register}
                                        checked={!!props.exercise.bodyWeight}
                                        onChange={(e) => changeCheckedAttribute('bodyWeight', e)}
                                    />
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.load ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.load} fullWidth>
                                            <InputLabel htmlFor="load">Load</InputLabel>
                                            <Select
                                                native
                                                label="Load"
                                                inputRef={register}
                                                value={props.exercise.load}
                                                onChange={(e) => changeAttribute('load', e)}
                                                inputProps={{
                                                    name: 'load',
                                                    id: 'load',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {loadValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.equipment ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.equipment} fullWidth>
                                            <InputLabel htmlFor="equipment">Equipment</InputLabel>
                                            <Select
                                                native
                                                label="Equipment"
                                                inputRef={register}
                                                value={props.exercise.equipment}
                                                onChange={(e) => changeAttribute('equipment', e)}
                                                inputProps={{
                                                    name: 'equipment',
                                                    id: 'equipment',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {equipmentValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.pushupMovement ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.pushupMovement} fullWidth>
                                            <InputLabel htmlFor="pushupMovement">Movement</InputLabel>
                                            <Select
                                                native
                                                label="Movement"
                                                inputRef={register}
                                                value={props.exercise.pushupMovement}
                                                onChange={(e) => changeAttribute('pushupMovement', e)}
                                                inputProps={{
                                                    name: 'pushupMovement',
                                                    id: 'pushupMovement',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {pushupMovementValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.distance ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.distance} fullWidth>
                                            <TextField name={"distance"}
                                                       id={"distance"}
                                                       label="Distance"
                                                       inputRef={register}
                                                       variant="outlined"
                                                       value={props.exercise.distance}
                                                       onChange={(e) => changeAttribute('distance', e)}
                                                       inputProps={{
                                                           maxLength: 6
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.speed ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.speed} fullWidth>
                                            <InputLabel htmlFor="speed">Speed</InputLabel>
                                            <Select
                                                native
                                                label="Speed"
                                                inputRef={register}
                                                value={props.exercise.speed}
                                                onChange={(e) => changeAttribute('speed', e)}
                                                inputProps={{
                                                    name: 'speed',
                                                    id: 'speed',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {speedValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.reps ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.reps} fullWidth>
                                            <InputLabel htmlFor="speed">Reps</InputLabel>
                                            <Select
                                                native
                                                label="Reps"
                                                inputRef={register}
                                                value={props.exercise.reps}
                                                onChange={(e) => changeAttribute('reps', e)}
                                                inputProps={{
                                                    name: 'reps',
                                                    id: 'reps',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {repsValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.chestPressReps ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.reps} fullWidth>
                                            <InputLabel htmlFor="speed">Reps</InputLabel>
                                            <Select
                                                native
                                                label="Reps"
                                                inputRef={register}
                                                value={props.exercise.reps}
                                                onChange={(e) => changeAttribute('reps', e)}
                                                inputProps={{
                                                    name: 'chestPressReps',
                                                    id: 'chestPressReps',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {chestPressRepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.sitToStandReps ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.sitToStandReps} fullWidth>
                                            <InputLabel htmlFor="speed">Reps</InputLabel>
                                            <Select
                                                native
                                                label="Reps"
                                                inputRef={register}
                                                value={props.exercise.reps}
                                                onChange={(e) => changeAttribute('reps', e)}
                                                inputProps={{
                                                    name: 'sitToStandReps',
                                                    id: 'sitToStandReps',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {sitToStandRepValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.sets ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.sets} fullWidth>
                                            <InputLabel htmlFor="speed">Sets</InputLabel>
                                            <Select
                                                native
                                                label="Sets"
                                                inputRef={register}
                                                value={props.exercise.sets}
                                                onChange={(e) => changeAttribute('sets', e)}
                                                inputProps={{
                                                    name: 'sets',
                                                    id: 'sets',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {setsValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.leftArmRightLeg ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.leftArmRightLeg} fullWidth>
                                            <InputLabel htmlFor="speed">L Arm/R Leg</InputLabel>
                                            <Select
                                                native
                                                label="L Arm/R Leg"
                                                inputRef={register}
                                                value={props.exercise.leftArmRightLeg}
                                                onChange={(e) => changeAttribute('leftArmRightLeg', e)}
                                                inputProps={{
                                                    name: 'leftArmRightLeg',
                                                    id: 'leftArmRightLeg',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {yesNoValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.rightArmLeftLeg ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.rightArmLeftLeg} fullWidth>
                                            <InputLabel htmlFor="speed">R Arm/L Leg</InputLabel>
                                            <Select
                                                native
                                                label="R Arm/L Leg"
                                                inputRef={register}
                                                value={props.exercise.rightArmLeftLeg}
                                                onChange={(e) => changeAttribute('rightArmLeftLeg', e)}
                                                inputProps={{
                                                    name: 'rightArmLeftLeg',
                                                    id: 'rightArmLeftLeg',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {yesNoValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.prompts ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.promptLevel} fullWidth>
                                            <InputLabel htmlFor="prompts">Prompt Level</InputLabel>
                                            <Select
                                                native
                                                label="Prompt Level"
                                                inputRef={register}
                                                value={props.exercise.promptLevel}
                                                onChange={(e) => changeAttribute('promptLevel', e)}
                                                inputProps={{
                                                    name: 'promptLevel',
                                                    id: 'promptLevel',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {promptsValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.prompts ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.promptFaded} fullWidth>
                                            <InputLabel htmlFor="prompts">Prompt Faded During Set</InputLabel>
                                            <Select
                                                native
                                                label="Prompt Faded"
                                                inputRef={register}
                                                value={props.exercise.promptFaded}
                                                onChange={(e) => changeAttribute('promptFaded', e)}
                                                inputProps={{
                                                    name: 'promptFaded',
                                                    id: 'promptFaded',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {yesNoValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.compensationPushup ?
                                    <Grid item xs={12}>
                                        <Box p={2} border={1} borderRadius={10} borderColor="grey.500">
                                            <FormControl fullWidth component="fieldset">
                                                <FormLabel component="legend">Compensation</FormLabel>
                                                <FormGroup row>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationShoulderElevation} fullWidth>
                                                                <InputLabel htmlFor="compensation">Shoulder Elevation</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Shoulder Elevation"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationShoulderElevation}
                                                                    onChange={(e) => changeAttribute('compensationShoulderElevation', e)}
                                                                    inputProps={{
                                                                        name: 'compensationShoulderElevation',
                                                                        id: 'compensationShoulderElevation',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationForwardHead} fullWidth>
                                                                <InputLabel htmlFor="compensation">Forward Head</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Forward Head"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationForwardHead}
                                                                    onChange={(e) => changeAttribute('compensationForwardHead', e)}
                                                                    inputProps={{
                                                                        name: 'compensationForwardHead',
                                                                        id: 'compensationForwardHead',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationFlairElbows} fullWidth>
                                                                <InputLabel htmlFor="compensation">Flair Elbows</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Flair Elbows"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationFlairElbows}
                                                                    onChange={(e) => changeAttribute('compensationFlairElbows', e)}
                                                                    inputProps={{
                                                                        name: 'compensationFlairElbows',
                                                                        id: 'compensationFlairElbows',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationNeutralSpine} fullWidth>
                                                                <InputLabel htmlFor="compensation">Neutral Spine</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Neutral Spine"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationNeutralSpine}
                                                                    onChange={(e) => changeAttribute('compensationNeutralSpine', e)}
                                                                    inputProps={{
                                                                        name: 'compensationNeutralSpine',
                                                                        id: 'compensationNeutralSpine',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationLowBackArch} fullWidth>
                                                                <InputLabel htmlFor="compensation">Low Back Arch</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Low Back Arch"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationLowBackArch}
                                                                    onChange={(e) => changeAttribute('compensationLowBackArch', e)}
                                                                    inputProps={{
                                                                        name: 'compensationLowBackArch',
                                                                        id: 'compensationLowBackArch',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.compensationOverheadSquat ?
                                    <Grid item xs={12}>
                                        <Box p={2} border={1} borderRadius={10} borderColor="grey.500">
                                            <FormControl fullWidth component="fieldset">
                                                <FormLabel component="legend">Compensation</FormLabel>
                                                <FormGroup row>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationLowBackArch} fullWidth>
                                                                <InputLabel htmlFor="compensation">Low Back Arch</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Low Back Arch"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationLowBackArch}
                                                                    onChange={(e) => changeAttribute('compensationLowBackArch', e)}
                                                                    inputProps={{
                                                                        name: 'compensationLowBackArch',
                                                                        id: 'compensationLowBackArch',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationExcessiveForwardLean} fullWidth>
                                                                <InputLabel htmlFor="compensation">Excessive Forward Lean</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Excessive Forward Lean"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationExcessiveForwardLean}
                                                                    onChange={(e) => changeAttribute('compensationExcessiveForwardLean', e)}
                                                                    inputProps={{
                                                                        name: 'compensationExcessiveForwardLean',
                                                                        id: 'compensationExcessiveForwardLean',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationFeetTurnOut} fullWidth>
                                                                <InputLabel htmlFor="compensation">Feet Turn Out</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Feet Turn Out"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationFeetTurnOut}
                                                                    onChange={(e) => changeAttribute('compensationFeetTurnOut', e)}
                                                                    inputProps={{
                                                                        name: 'compensationFeetTurnOut',
                                                                        id: 'compensationFeetTurnOut',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationKneesMoveInward} fullWidth>
                                                                <InputLabel htmlFor="compensation">Knees Move Inward</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Knees Move Inward"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationKneesMoveInward}
                                                                    onChange={(e) => changeAttribute('compensationKneesMoveInward', e)}
                                                                    inputProps={{
                                                                        name: 'compensationKneesMoveInward',
                                                                        id: 'compensationKneesMoveInward',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationFeetFlatten} fullWidth>
                                                                <InputLabel htmlFor="compensation">Feet Flatten</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Feet Flatten"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationFeetFlatten}
                                                                    onChange={(e) => changeAttribute('compensationFeetFlatten', e)}
                                                                    inputProps={{
                                                                        name: 'compensationFeetFlatten',
                                                                        id: 'compensationFeetFlatten',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationKneesMoveOutward} fullWidth>
                                                                <InputLabel htmlFor="compensation">Knees Move Outward</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Knees Move Outward"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationKneesMoveOutward}
                                                                    onChange={(e) => changeAttribute('compensationKneesMoveOutward', e)}
                                                                    inputProps={{
                                                                        name: 'compensationKneesMoveOutward',
                                                                        id: 'compensationKneesMoveOutward',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationArmsFallForward} fullWidth>
                                                                <InputLabel htmlFor="compensation">Arms Fall Forward</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Arms Fall Forward"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationArmsFallForward}
                                                                    onChange={(e) => changeAttribute('compensationArmsFallForward', e)}
                                                                    inputProps={{
                                                                        name: 'compensationArmsFallForward',
                                                                        id: 'compensationArmsFallForward',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationAsymmetricWeightShift} fullWidth>
                                                                <InputLabel htmlFor="compensation">Asymmetric Weight Shift</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Asymmetric Weight Shift"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationAsymmetricWeightShift}
                                                                    onChange={(e) => changeAttribute('compensationAsymmetricWeightShift', e)}
                                                                    inputProps={{
                                                                        name: 'compensationAsymmetricWeightShift',
                                                                        id: 'compensationAsymmetricWeightShift',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationHeelsRise} fullWidth>
                                                                <InputLabel htmlFor="compensation">Heels Rise</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Heels Rise"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationHeelsRise}
                                                                    onChange={(e) => changeAttribute('compensationHeelsRise', e)}
                                                                    inputProps={{
                                                                        name: 'compensationHeelsRise',
                                                                        id: 'compensationHeelsRise',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationLowBackRounds} fullWidth>
                                                                <InputLabel htmlFor="compensation">Low Back Rounds</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Low Back Rounds"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationLowBackRounds}
                                                                    onChange={(e) => changeAttribute('compensationLowBackRounds', e)}
                                                                    inputProps={{
                                                                        name: 'compensationLowBackRounds',
                                                                        id: 'compensationLowBackRounds',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.compensationStaticPosture ?
                                    <Grid item xs={12}>
                                        <Box p={2} border={1} borderRadius={10} borderColor="grey.500">
                                            <FormControl fullWidth component="fieldset">
                                                <FormLabel component="legend">Compensation</FormLabel>
                                                <FormGroup row>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationPesPlanusSyndrome} fullWidth>
                                                                <InputLabel htmlFor="compensation">Pes Planus Syndrome</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Pes Planus Syndrome"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationPesPlanusSyndrome}
                                                                    onChange={(e) => changeAttribute('compensationPesPlanusSyndrome', e)}
                                                                    inputProps={{
                                                                        name: 'compensationPesPlanusSyndrome',
                                                                        id: 'compensationPesPlanusSyndrome',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationUpperCrossedSyndrome} fullWidth>
                                                                <InputLabel htmlFor="compensation">Upper Crossed Syndrome</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Upper Crossed Syndrome"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationUpperCrossedSyndrome}
                                                                    onChange={(e) => changeAttribute('compensationUpperCrossedSyndrome', e)}
                                                                    inputProps={{
                                                                        name: 'compensationUpperCrossedSyndrome',
                                                                        id: 'compensationUpperCrossedSyndrome',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl error={!!errors.compensationLowerCrossedSyndrome} fullWidth>
                                                                <InputLabel htmlFor="compensation">Lower Crossed Syndrome</InputLabel>
                                                                <Select
                                                                    native
                                                                    label="Lower Crossed Syndrome"
                                                                    inputRef={register}
                                                                    value={props.exercise.compensationLowerCrossedSyndrome}
                                                                    onChange={(e) => changeAttribute('compensationLowerCrossedSyndrome', e)}
                                                                    inputProps={{
                                                                        name: 'compensationLowerCrossedSyndrome',
                                                                        id: 'compensationLowerCrossedSyndrome',
                                                                    }}
                                                                >
                                                                    <option aria-label="Select" value="" />
                                                                    {yesNoValues.map((item, key) => (
                                                                        <option key={key} value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </FormGroup>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    : ''}

                                {!props.isAssessmentExercise ?
                                    <Grid item xs={12} sm={5}>
                                        <FormControl error={!!errors.removeVisualTaskCompletion} fullWidth>
                                            <InputLabel htmlFor="removeVisualTaskCompletion">Remove Visual/Task Completion</InputLabel>
                                            <Select
                                                native
                                                label="Remove Visual/Task Completion"
                                                inputRef={register}
                                                value={props.exercise.removeVisualTaskCompletion}
                                                onChange={(e) => changeAttribute('removeVisualTaskCompletion', e)}
                                                inputProps={{
                                                    name: 'removeVisualTaskCompletion',
                                                    id: 'removeVisualTaskCompletion',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {promptIndependentValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {!props.isAssessmentExercise ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControl error={!!errors.choseNextExercise} fullWidth>
                                            <InputLabel htmlFor="choseNextExercise">Chose Next Exercise</InputLabel>
                                            <Select
                                                native
                                                label="Chose Next Exercise"
                                                inputRef={register}
                                                value={props.exercise.choseNextExercise}
                                                onChange={(e) => changeAttribute('choseNextExercise', e)}
                                                inputProps={{
                                                    name: 'choseNextExercise',
                                                    id: 'choseNextExercise',
                                                }}
                                            >
                                                <option aria-label="Select" value="" />
                                                {promptIndependentValues.map((item, key) => (
                                                    <option key={key} value={item}>{item}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="partiallyComplete" />}
                                        label="Client Partially Completed Movement"
                                        inputRef={register}
                                        checked={!!props.exercise.partiallyComplete}
                                        onChange={(e) => changeCheckedAttribute('partiallyComplete', e)}
                                    />
                                </Grid>

                                {props.exerciseConfig.other ?
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField name={"notes"}
                                                       id={"notes"}
                                                       label="Notes"
                                                       inputRef={register}
                                                       variant="outlined"
                                                       multiline
                                                       value={props.exercise.notes}
                                                       onChange={changeNotes}
                                                       inputProps={{
                                                           maxLength: 2500
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : ''}

                                {props.exerciseConfig.otherAssesment ?
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField name={"otherAssesment"}
                                                       id={"otherAssesment"}
                                                       label="Recomendations"
                                                       inputRef={register}
                                                       variant="outlined"
                                                       multiline
                                                       value={props.exercise.notes}
                                                       onChange={changeNotes}
                                                       inputProps={{
                                                           maxLength: 2500
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    : ''}
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                            {!props.isAssessmentExercise ?
                                <Box mx={2}>
                                    <Button size="small" endIcon={<DeleteIcon/>} onClick={handleDiscardExerciseDialogOpen}>Discard Movement</Button>
                                </Box>
                            : ''}
                            <Box mx={2}>
                                <Button type="submit" size="small" variant="contained" color="primary" endIcon={<CheckIcon/>}>Save</Button>
                            </Box>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </Box>
            </form>

            <Dialog
                open={discardExerciseDialogIsOpen}
                onClose={handleDiscardExerciseDialogClose}
                aria-labelledby="discard-exercise-alert-dialog-title"
                aria-describedby="discard-exercise-alert-dialog-description"
            >
                <DialogTitle id="discard-exercise-alert-dialog-title">{"Discard Movement: Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="discard-exercise-alert-dialog-description">
                        Are you sure you want to discard this movement?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiscardExerciseDialogClose} color="secondary" autoFocus>
                        No, Keep It
                    </Button>
                    <Button onClick={discardExercise(props.exercise)}>
                        Yes, Discard It
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};