import React from 'react';
import ReactGA from 'react-ga';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PreviousStepButton from "./buttons/PreviousStepButton";
import DiscardWorkoutButton from "./buttons/DiscardWorkoutButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller, useForm} from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {TextField} from "@material-ui/core";
import MoodPicker from "./MoodPicker";
import {setNavigationToClientDashboardPage} from "../../reducers/navigateSlice";
import {removeClient} from "../../reducers/clientSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import CheckIcon from '@material-ui/icons/Check';
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";

export default function PostWorkoutQuestions(props) {

    const {control, register, watch, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const preWorkoutData = useSelector(store => store.preWorkoutData);
    const exercises = useSelector(store => store.exercises);
    const client = useSelector(store => store.client);
    const user = useSelector(store => store.user);

    const [otherChecked, setOtherChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    const validate = () => {
        const behaviorNone = watch('behaviorNone');
        const behaviorHandBiting = watch('behaviorHandBiting');
        const behaviorHeadShakeNo = watch('behaviorHeadShakeNo');
        const behaviorDestructiveOfProperty = watch('behaviorDestructiveOfProperty');
        const behaviorGrabbing = watch('behaviorGrabbing');
        const behaviorAggressiveTowardsStaff = watch('behaviorAggressiveTowardsStaff');
        const behaviorOther = watch('behaviorOther');

        const isValid = !!(behaviorNone || behaviorHandBiting || behaviorHeadShakeNo || behaviorDestructiveOfProperty ||
            behaviorGrabbing || behaviorAggressiveTowardsStaff || behaviorOther);

        console.log('checkboxes valid?', isValid);

        return isValid;
    };

    const behaviorError = () => {
        return errors.behaviorNone && errors.behaviorHandBiting && errors.behaviorHeadShakeNo && errors.behaviorDestructiveOfProperty &&
            errors.behaviorGrabbing && errors.behaviorAggressiveTowardsStaff && errors.behaviorOther;
    };

    const handleClose = () => {
        dispatch(setNavigationToClientDashboardPage());
        setOpen(false);
    };

    const onSubmit = postWorkoutData => {

        setRequestInProgress(true);

        console.log(JSON.stringify(user));
        console.log(JSON.stringify(client));
        console.log(JSON.stringify(preWorkoutData));
        console.log(JSON.stringify(exercises));
        console.log(JSON.stringify(postWorkoutData));

        const workout = {user: user, client: client, preWorkoutData: preWorkoutData, exercises: exercises, postWorkoutData: postWorkoutData};

        console.log(JSON.stringify(workout));

        getClient().post('/api/workout', workout)
            .then((response) => {
                console.log(response);
                setOpen(true);
                ReactGA.event({
                    category: 'Workout',
                    action: 'Completed'
                });
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorDetails: error.message}));
            })
            .then(() => {
                setRequestInProgress(false);
            });
    };

    const handleChangeInBehaviorOther = (event) => {
        setOtherChecked(event.target.checked);
    };

    return (
        <div style={!props.show ? {display: "none"} : {}}>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box py={2} maxWidth={1000} mx="auto">
                        <Grid container spacing={3} justify="center" style={{paddingBottom: 25}}>
                            <Grid item xs={12} md={6}>
                                <MoodPicker register={register} errors={errors}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl error={!!errors.howWasScheduleCreated} fullWidth>
                                    <InputLabel htmlFor="howWasScheduleCreated">* How was the schedule created?</InputLabel>
                                    <Select
                                        native
                                        inputRef={register({ required: true })}
                                        label="How was the schedule created?"
                                        inputProps={{
                                            name: 'howWasScheduleCreated',
                                            id: 'howWasScheduleCreated',
                                        }}
                                    >
                                        <option aria-label="Select" value="" />
                                        <option value="N/A">N/A</option>
                                        <option value="Trainer Chose">Trainer Chose</option>
                                        <option value="Trainer Gave 2 Choices">Trainer Gave 2 Choices</option>
                                        <option value="Trainer Chose Half, Client Chose Half">Trainer Chose Half, Client Chose Half</option>
                                        <option value="Client Chose All">Client Chose All</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl error={!!errors.howManyBreaksTaken} fullWidth>
                                    <InputLabel htmlFor="howManyBreaksTaken">How many breaks did the client take?</InputLabel>
                                    <Select
                                        native
                                        inputRef={register}
                                        label="How many breaks did the client take?"
                                        inputProps={{
                                            name: 'howManyBreaksTaken',
                                            id: 'howManyBreaksTaken',
                                        }}
                                    >
                                        <option aria-label="Select" value="" />
                                        <option value={"N/A"}>N/A</option>
                                        <option value={"0"}>0</option>
                                        <option value={"1"}>1</option>
                                        <option value={"2"}>2</option>
                                        <option value={"3"}>3</option>
                                        <option value={"4"}>4</option>
                                        <option value={"5"}>5</option>
                                        <option value={"6"}>6</option>
                                        <option value={"7"}>7</option>
                                        <option value={"8"}>8</option>
                                        <option value={"9"}>9</option>
                                        <option value={"10"}>10</option>
                                        <option value={"11"}>11</option>
                                        <option value={"12"}>12</option>
                                        <option value={"13"}>13</option>
                                        <option value={"14"}>14</option>
                                        <option value={"15"}>15</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl error={!!errors.numberTimesRedirected} fullWidth>
                                    <InputLabel htmlFor="numberTimesRedirected">Number of times redirected</InputLabel>
                                    <Select
                                        native
                                        inputRef={register}
                                        label="Number of times redirected"
                                        inputProps={{
                                            name: 'numberTimesRedirected',
                                            id: 'numberTimesRedirected',
                                        }}
                                    >
                                        <option aria-label="Select" value="" />
                                        <option value={"N/A"}>N/A</option>
                                        <option value={"0"}>0</option>
                                        <option value={"1"}>1</option>
                                        <option value={"2"}>2</option>
                                        <option value={"3"}>3</option>
                                        <option value={"4"}>4</option>
                                        <option value={"5"}>5</option>
                                        <option value={"6"}>6</option>
                                        <option value={"7"}>7</option>
                                        <option value={"8"}>8</option>
                                        <option value={"9"}>9</option>
                                        <option value={"10"}>10</option>
                                        <option value={"11"}>11</option>
                                        <option value={"12"}>12</option>
                                        <option value={"13"}>13</option>
                                        <option value={"14"}>14</option>
                                        <option value={"15"}>15</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Prompting Section */}
                        <Grid container spacing={3} justify="center" style={{paddingBottom: 25}}>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors.visualScheduleUsed} component="fieldset">
                                    <FormLabel component="legend">* Did client use visual schedule?</FormLabel>
                                    <Controller
                                        as={<RadioGroup
                                            row
                                            aria-label="visualScheduleUsed"
                                        >
                                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                        </RadioGroup>}

                                        name="visualScheduleUsed"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: true }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors.whoCounted} component="fieldset">
                                    <FormLabel component="legend">Who performed the counting?</FormLabel>
                                    <Controller
                                        as={<RadioGroup
                                            row
                                            aria-label="whoCounted"
                                        >
                                            <FormControlLabel value={"Client"} control={<Radio />} label="Client" />
                                            <FormControlLabel value={"Trainer / Assistant"} control={<Radio />} label="Trainer / Assistant" />
                                        </RadioGroup>}

                                        name="whoCounted"
                                        defaultValue={""}
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors.didClientStopExercisingAtCompletion} component="fieldset">
                                    <FormLabel component="legend">Did client stop exercising at completion?</FormLabel>
                                    <Controller
                                        as={<RadioGroup
                                            row
                                            aria-label="didClientStopExercisingAtCompletion"
                                        >
                                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                                        </RadioGroup>}

                                        name="didClientStopExercisingAtCompletion"
                                        defaultValue={""}
                                        control={control}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Behaviors */}
                        <Grid container spacing={3} justify="center" style={{paddingBottom: 25}}>
                            <Box p={2} border={1} borderRadius={10} borderColor="grey.500">
                                <Grid item xs={12}>
                                    <FormControl fullWidth component="fieldset" error={behaviorError()}>
                                        <FormLabel component="legend">Behaviors Displayed</FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorNone" />}
                                                label="None"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorHandBiting" />}
                                                label="Hand Biting"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorHeadShakeNo" />}
                                                label="Head Shake No"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorDestructiveOfProperty" />}
                                                label="Destructive Of Property"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorGrabbing" />}
                                                label="Grabbing"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox name="behaviorAggressiveTowardsStaff" />}
                                                label="Aggressive Towards Staff"
                                                inputRef={register({ validate })}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox onChange={handleChangeInBehaviorOther} name="behaviorOther" />}
                                                label="Other"
                                                inputRef={register({ validate })}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                {otherChecked ?
                                    <Box py={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField name="otherBehaviorDescription"
                                                           variant="outlined"
                                                           multiline
                                                           inputRef={register}
                                                           id="otherBehaviorDescription"
                                                           label="Other Behavior Exhibited"
                                                           inputProps={{
                                                               maxLength: 100
                                                           }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Box>

                                    : ''}
                            </Box>
                        </Grid>

                        {/* Notes */}
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField name="notes"
                                               variant="outlined"
                                               multiline
                                               inputRef={register}
                                               id="notes"
                                               label="Workout Notes / Area of Focus Needed"
                                               inputProps={{
                                                   maxLength: 2500
                                               }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Buttons */}

                        <Grid container justify="center" style={{padding: 25}}>
                            <Box component="span" m={0.5}>
                                <PreviousStepButton/>
                            </Box>
                            <Box component="span" m={0.5}>
                                <DiscardWorkoutButton/>
                            </Box>
                            <Box component="span" m={0.5}>
                                <Button type="submit" variant="contained" color="primary" endIcon={<CheckIcon/>} disabled={requestInProgress}>
                                    Submit Workout
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                </form>
            </Container>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="workout-subbmitted-alert-dialog-title"
                aria-describedby="workout-subbmitted-alert-dialog-description"
            >
                <DialogTitle id="workout-subbmitted-alert-dialog-title">{"Workout Submitted"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="workout-subbmitted-alert-dialog-description">
                        Your workout for {client.name} has been successfully submitted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};