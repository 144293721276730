import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Exercise from "./Exercise";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {addExercise, removeExercise} from "../../reducers/exercisesSlice";
import PreviousStepButton from "./buttons/PreviousStepButton";
import DiscardWorkoutButton from "./buttons/DiscardWorkoutButton";
import {nextWorkoutStep} from "../../reducers/workoutStepSlice";
import WorkoutStepButtonDivider from "./WorkoutStepButtonDivider";
import Container from "@material-ui/core/Container";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from '@material-ui/icons/Add';

export default function Exercises() {

    const exercises = useSelector(store => store.exercises);
    const user = useSelector(store => store.user);

    const dispatch = useDispatch();

    const [availableExercises, setAvailableExercises] = React.useState(allExercises);
    const [availableExerciseTypes, setAvailableExerciseTypes] = React.useState([]);
    const [filteredExercises, setFilteredExercises] = React.useState([]);
    const [newExerciseType, setNewExerciseType] = React.useState("");
    const [newExercise, setNewExercise] = React.useState("");
    const [addExerciseDialogIsOpen, setAddExerciseDialogIsOpen] = React.useState(false);
    const [completeAllExercisesDialogIsOpen, setCompleteAllExercisesDialogIsOpen] = React.useState(false);
    const [exerciseFormFieldError, setExerciseFormFieldError] = React.useState(false);

    useEffect(() => {
        if (exercises.length === 0) {
            setAddExerciseDialogIsOpen(true);
        }

        let exercisesFilteredToPackage = allExercises.filter(function(e) {
            return e.packageLevel <= user.packageLevel;
        });

        console.log('exercisesFilteredToPackage length is ' + exercisesFilteredToPackage.length);
        setAvailableExercises(exercisesFilteredToPackage);
        setFilteredExercises(exercisesFilteredToPackage);

        let exerciseTypes = exercisesFilteredToPackage.map(a => a.type);
        let uniqueExerciseTypes = [...new Set(exerciseTypes)];
        setAvailableExerciseTypes(uniqueExerciseTypes.sort());

    }, [dispatch, exercises]);

    const getExerciseConfigFromName = (exerciseName) => {
        return allExercises.find(exerciseConfig => exerciseConfig.name === exerciseName);
    };

    const handleOpenExerciseDialog = () => {
        setAddExerciseDialogIsOpen(true);
    };

    const handleCloseCompleteAllExercisesDialog = () => {
        setCompleteAllExercisesDialogIsOpen(false);
    };

    const getAddExerciseButtonLabel = () => {
        if (exercises.length === 0) {
            return 'Add Movement';
        } else {
            return 'Add Another Movement';
        }
    };

    const handleAddExercise = () => {
        if (!newExercise) {
            setExerciseFormFieldError(true);
        } else {
            const exerciseConfig = getExerciseConfigFromName(newExercise);
            dispatch(addExercise({type: exerciseConfig.type , name: newExercise}));
            setExerciseFormFieldError(false);
            setAddExerciseDialogIsOpen(false);
        }
        setNewExerciseType(null);
        setNewExercise(null);
    };

    const handleCancelAddExercise = () => {
        setAddExerciseDialogIsOpen(false);
        setNewExerciseType(null);
        setNewExercise(null);
    };

    const handleNewExerciseTypeChange = (event) => {
        let newValue = event.target.value;
        setNewExerciseType(newValue);

        // If nothing is selected, show all exercises
        let newFilteredResults = availableExercises;
        if (newValue) {
            newFilteredResults = availableExercises.filter(function(e) {
                return e.type === event.target.value;
            });
        }
        setFilteredExercises(newFilteredResults);
    };

    const handleNewExerciseChange = (event) => {
        setNewExercise(event.target.value);
    };

    const handleDiscardExercise = (exercise) => {
        dispatch(removeExercise(exercise));
    };

    const handleNext = () => {
        // Make sure all exercises are finished
        const unfinished = exercises.find(exercise => exercise.finished !== true);
        if (unfinished) {
            setCompleteAllExercisesDialogIsOpen(true);
        } else {
            dispatch(nextWorkoutStep());
        }
    };

    return (
        <Container style={{paddingTop: 20}}>
            {exercises.map((exercise) => {
                const exerciseConfig = getExerciseConfigFromName(exercise.name);
                return (<Exercise key={exercise.id} exercise={exercise} exerciseConfig={exerciseConfig} discarder={handleDiscardExercise}/>)
            })}

            <Grid container justify="center">
                <Box component="span" mx={0.5} mt={1}>
                    <Button startIcon={<AddIcon/>} onClick={handleOpenExerciseDialog}>
                        {getAddExerciseButtonLabel()}
                    </Button>
                </Box>
            </Grid>

            <WorkoutStepButtonDivider/>

            <Grid container justify="center" style={{paddingBottom: 20}}>
                <Box component="span" m={0.5}>
                    <PreviousStepButton/>
                </Box>
                <Box component="span" m={0.5}>
                    <DiscardWorkoutButton/>
                </Box>
                <Box component="span" m={0.5}>
                    <Button variant="contained" color="secondary" endIcon={<ArrowForwardIosIcon/>} onClick={handleNext}>
                        Next
                    </Button>
                </Box>
            </Grid>

            <Dialog open={addExerciseDialogIsOpen} onClose={handleAddExercise} aria-labelledby="choose-exercise-dialog">
                <DialogTitle id="choose-exercise-dialog">Add Movement</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose the movement you would like to add to the workout.
                    </DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel id="new-exercise-type">Type</InputLabel>
                        <Select
                            native
                            labelId="new-execrcise-type"
                            id="new-exercise-type"
                            value={newExerciseType}
                            onChange={handleNewExerciseTypeChange}
                        >
                            <option aria-label="Select" value="" />
                            {availableExerciseTypes.map((e, key) => (
                                <option key={key} value={e}>{e}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl error={exerciseFormFieldError} fullWidth required style={{marginTop: 14}}>
                        <InputLabel id="new-exercise">Movement</InputLabel>
                        <Select
                            native
                            labelId="new-execrcise"
                            id="new-exercise"
                            value={newExercise}
                            onChange={handleNewExerciseChange}
                        >
                            <option aria-label="Select" value="" />
                            {filteredExercises.map((e, key) => (
                                <option key={key} value={e.name}>{e.name}</option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAddExercise} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleAddExercise} color="primary" variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={completeAllExercisesDialogIsOpen} aria-labelledby="complete-exercises-alert-dialog">
                <DialogTitle id="complete-exercises-alert-dialog">Complete Movements</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please save all movements before continuing.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompleteAllExercisesDialog} variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

const allExercises = [
    // {
    //     "name": "0 Dummy All",
    //     "type": "None",
    //     "position": true,
    //     "progressionHipBridge": true,
    //     "progressionPress": true,
    //     "progressionPushUp": true,
    //     "progressionSquat": true,
    //     "progressionStepUps": true,
    //     "progressionOverheadPress": true,
    //     "height": true,
    //     "time": true,
    //     "timeunits": true,
    //     "weight": true,
    //     "bodyWeight": true,
    //     "load": true,
    //     "resistanceBand": true,
    //     "equipment": true,
    //     "distance": true,
    //     "speed": true,
    //     "reps": true,
    //     "sets": true,
    //     "prompts": true,
    //     "other": true,
    //     "packageLevel": 0
    // },
    // {
    //     "name": "1 Dummy None",
    //     "type": "None",
    //     "position": false,
    //     "progressionHipBridge": false,
    //     "progressionPress": false,
    //     "progressionPushUp": false,
    //     "progressionSquat": false,
    //     "progressionStepUps": false,
    //     "progressionOverheadPress": false,
    //     "height": false,
    //     "time": false,
    //     "timeunits": false,
    //     "weight": false,
    //     "load": false,
    //     "resistanceBand": false,
    //     "equipment": false,
    //     "distance": false,
    //     "speed": false,
    //     "reps": false,
    //     "sets": false,
    //     "prompts": false,
    //     "other": false,
    //     "packageLevel": 0
    // },
    {
        "name": "Anti-Rotation Hold",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Assisted Pullup",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Back Stretches",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Ball Back Extension",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Ball Crunch",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Ball March",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Ball Yta",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Bar Stretches",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Battlerope",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bear Crawl",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bench Dip",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Bench Hop",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bench Knee Ups",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Bench Mountain Climber",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bench Plank",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Bent Over Reverse Fly",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Bent Over Row",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Bicycle Crunch",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Birddog",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Bosu Balance",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Burpee",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Core Twist",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Front Lunge",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Hip Bridge",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Lift",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Plank",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Pushup",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Side Lunge",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Squat",
        "type": "Bosu",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Toe Touch",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Bosu Up And Over",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Box Jump",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": true,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Calf Raise",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Clamshell",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Cone Relay",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Crabwalk",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Crunch",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Double Arm Bicep",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionDoubleArmBicep": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Double Arm Tricep",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionDoubleArmTricep": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "DB Deadlift",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Deadbug",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Donkey Kick",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Dumbbell Shoulder Shrug",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Elbow Plank",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Elevated Deadlift",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Elliptical",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": true,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Face Pull",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Farmer Carry",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": true,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Flutterkick",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Front Raise",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Glider Pike",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Glider Slide",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Glider Walk",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Glute Kickback",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Half Kneel Kettlebell Pull",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Half Kneel MB Chop",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Hanging Knee Ups",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Hip Bridge",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": true,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Hip Flexor Stretch",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Hulk Smash",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Hurdle Front, Side Step",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Hurdles",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Jump Rope",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Jumping Jacks",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "KB Clean And Jerk",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "KB Swing",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Knee To Elbow",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Kneeling Kettlebell Pull",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Kneeling Row",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Krankcycle",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": false,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Ladder Drills",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Lat Pulldown",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Lat Raise",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Lateral Cone Shuffle",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Lateral Hurdles",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Leg Curl",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionLegCurl": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Leg Extension",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Leg Press",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Line Jump",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Lunge",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionLunge": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Monster Steps",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": true,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Mountain Climber",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Nustep/Recumbant Bike",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": true,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": true,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Other",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Overhead Carry",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": true,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Overhead Hold",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Overhead Press",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Partner Lunge",
        "type": "Partner",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Partner Pass",
        "type": "Partner",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Partner Throwdown",
        "type": "Partner",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Press",
        "type": "Chest",
        "position": true,
        "progressionHipBridge": false,
        "progressionPress": true,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Prone Goalie",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Pull Aparts",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Push Up",
        "type": "Chest",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": true,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Quad Run",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "RB Abductor",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Renegade Row",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Resistance Band Adductor",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Resistance Band Chop",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Resistance Band Lower Leg",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Resisted Run",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": true,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Reverse Lunge",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Reverse Plank",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Ripcord",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Rope Pull",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Rower",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": true,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "SB Lunge",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Seated KB Core Twist",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Seated Kettlebell Pull",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Seated March",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Seated MB Core Twist",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Seated Row",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Seated Toe Raise",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Shotput",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Side Plank",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Single Arm Bicep",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionSingleArmBicep": true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Single Arm Tricep",
        "type": "Arms",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "progressionSingleArmTricep" : true,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Single Leg Reach",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Sled Pull",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": true,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Squat",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": true,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Squat Bowling",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Squat Jump",
        "type": "Power/Agility",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Squat Roll To Stand",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Standing Chop",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Standing Kettlebell Pull",
        "type": "Games",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Standing Row",
        "type": "Back/Shoulders",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": true,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Standing Yta",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Star Jump",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Stationary Bike",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": true,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Step Up To OH Press",
        "type": "Total Body",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "heightStepUpToOHPress": true,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 40
    },
    {
        "name": "Step Ups",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": true,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Straight Arm Plank",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": false,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Treadmill",
        "type": "Cardio",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": true,
        "speed": true,
        "reps": false,
        "sets": false,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Turtle",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Walkout Plank",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 10
    },
    {
        "name": "Wall Angel",
        "type": "Stretch",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Wall Ball Slam",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 30
    },
    {
        "name": "Wall Sit",
        "type": "Legs",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": true,
        "timeunits": true,
        "weight": false,
        "load": false,
        "resistanceBand": false,
        "equipment": false,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    },
    {
        "name": "Weighted Side Bend",
        "type": "Core",
        "position": false,
        "progressionHipBridge": false,
        "progressionPress": false,
        "progressionPushUp": false,
        "progressionSquat": false,
        "progressionStepUps": false,
        "progressionOverheadPress": false,
        "height": false,
        "time": false,
        "timeunits": false,
        "weight": true,
        "bodyWeight": true,
        "load": false,
        "resistanceBand": false,
        "equipment": true,
        "distance": false,
        "speed": false,
        "reps": true,
        "sets": true,
        "prompts": true,
        "other": true,
        "packageLevel": 20
    }
];